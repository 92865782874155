const MTMLibrary = {
  /**
   * Scrolls to the element with a special Id
   * @param id
   * @param offset
   * @param time
   */

  scrollToId: (id, offset, time) => {
    const selector = jQuery('#' + id);
    if (selector.length <= 0) {
      return;
    }

    if (typeof offset === 'undefined') {
      offset = 100;
    }

    if (typeof time === 'undefined') {
      time = 750;
    }

    jQuery('html, body').animate({
      scrollTop: selector.offset().top - offset
    }, time);
  },

  toggleSearch: action => {
    const search = $('.mtm-header-search');
    const searchWrapper = $('.search-form-header');
    const toggler = $('.navbar-toggler');
    if (action === 'show') {
      search.css({'top': 0});
      if (toggler.hasClass('toggled')) {
        $('html').removeClass('nav-open');
        $('.navbar-translate').css({opacity: 0});
      }
      searchWrapper.find('> div:not(.mtm-header-search)').css({opacity: 0});
      search.find('[name="s"]').focus();
    } else {
      search.css({'top': '-200%'});
      searchWrapper.find('> div:not(.mtm-header-search)').css({opacity: 1});
      if (toggler.hasClass('toggled')) {
        toggler.removeClass('toggled').show();
      }
    }
  }
}

const setUpContents = function () {
  const container = $('#table-contents-container');
  if (container.length !== 1) {
    return;
  }

  let counter = 0;
  let list = container.find('.table-contents');
  container.addClass('animated wow fadeIn');
  for (let i of $('.post-content').find('h2, h3, h4, h5')) {
    counter++;
    if ($(i).hasClass('table-contents-title')) {
      continue;
    }
    $(i).attr('id', `title-${counter}`);
    let item = document.createElement('li');
    $(item).addClass(`title-${i.tagName}`);
    $(item).html(`<a href="javascript:void(0)" onclick="MTMLibrary.scrollToId('${$(i).attr('id')}', 150, 300)">${$(i).text()}</a>`);
    list.append(item);
  }
  container.find('.spinner').remove();

};


$(() => {
  $('.mtm-search-submit').click(() => {
    $('#mtmSearchForm').submit();
  })

  $('.mtm-search-close').click(() => {
    MTMLibrary.toggleSearch('hide');
  })

  $('.mtm-search-show').click(() => {
    MTMLibrary.toggleSearch('show');
  })

  setUpContents()
})


/*========== WAYPOINTS ANIMATION DELAY ==========*/
//Original Resource: https://www.oxygenna.com/tutorials/scroll-animations-using-waypoints-js-animate-css
$(function () { // a self calling function
  function onScrollInit(items, trigger) { // a custom made function
    items.each(function () { //for every element in items run function
      var osElement = $(this), //set osElement to the current
        osAnimationClass = osElement.attr('data-animation'), //get value of attribute data-animation type
        osAnimationDelay = osElement.attr('data-delay'); //get value of attribute data-delay time

      osElement.css({ //change css of element
        '-webkit-animation-delay': osAnimationDelay, //for safari browsers
        '-moz-animation-delay': osAnimationDelay, //for mozilla browsers
        'animation-delay': osAnimationDelay //normal
      });

      var osTrigger = (trigger) ? trigger : osElement; //if trigger is present, set it to osTrigger. Else set osElement to osTrigger

      osTrigger.waypoint(function () { //scroll upwards and downwards
        osElement.addClass('animated').addClass(osAnimationClass); //add animated and the data-animation class to the element.
      }, {
        triggerOnce: true, //only once this animation should happen
        offset: '90%' // animation should happen when the element is 70% below from the top of the browser window
      });
    });
  }

  onScrollInit($('.os-animation')); //function call with only items
  onScrollInit($('.staggered-animation'), $('.staggered-animation-container')); //function call with items and trigger
});
